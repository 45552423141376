import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Link from "gatsby-link";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";
import Temoignages from "../components/home/bloc-temoignages";

const ImageWrapper1 = styled(Img)`
  @media (min-width: 992px) {
    height: 320px;
  }

  img {
    object-position: top !important;
    max-width: none !important;
  }
`;

const ImageWrapper2 = styled(Img)`
  @media (min-width: 992px) {
    height: 400px;
  }
  img {
    object-position: top !important;
    max-width: none !important;
  }
`;

const ImageWrapper3 = styled(Img)`
  @media (min-width: 992px) {
    height: 750px;
  }
  img {
    object-position: top !important;
    max-width: none !important;
  }
`;

const EtudeDeCasTemplate = ({
  data // this prop will be injected by the GraphQL query below.
}) => {
  // const allMarkdownRemark = data.allMarkdownRemark.edges;
  const { markdownRemark } = data;

  const getCurrentMarkdown = () => {
    return data.allMarkdownRemark.edges.filter(
      item => item.node.frontmatter.path === markdownRemark.frontmatter.path
    );
  };

  // let allMarkdownRemarkFiltered = allMarkdownRemark.filter(elem => {
  //   return elem.node.frontmatter.title !== markdownRemark.frontmatter.title;
  // });
  // if (allMarkdownRemarkFiltered.length === 3) {
  //   allMarkdownRemarkFiltered.pop();
  // }
  const { frontmatter, html } = markdownRemark;
  const {
    title,
    description,
    client,
    technologies,
    prestation,
    website,
    date,
    image1,
    image2,
    image3,
    image4,
    content,
    author,
    linkedin,
    role,
    picture
  } = frontmatter;
  return (
    <Layout className="etude-de-cas-layout">
      <SEO title={title} description={markdownRemark.excerpt} />
      <Banner
        title={client}
        imageBanner={data.imageBanner.childImageSharp.fluid}
        baseline={description}
      />

      <section
        id="portfolio-single"
        className="portfolio portfolio-single portfolio-single-2"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ul className="portfolio-meta list-unstyled mb-30">
                <li>
                  <div>Client :</div>
                  <h5>{client}</h5>
                </li>
                <li>
                  <div>Prestation :</div>
                  <h5>{prestation}</h5>
                </li>
                <li>
                  <div>Technos :</div>
                  <h5>{technologies}</h5>
                </li>
                <li>
                  <div>Date :</div>
                  <h5>{date}</h5>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="portfolio-title">
                <h2>{title}</h2>
              </div>
              <div className="portfolio-desc">
                <div
                  className="entry-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                <a
                  className="btn  btn-secondary btn-hover"
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </section>
      {author && (
        <Temoignages
          temoignagesData={[
            {
              content,
              author,
              linkedin,
              role,
              testimonialPicture: picture
            }
          ]}
        />
      )}

      <section
        className={`portfolio portfolio-single portfolio-single-2 pb-30 ${
          author ? "pt-100" : "pt-15"
        }`}
      >
        <div className="container">
          <div className="row portfolio-images clearfix">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <ImageWrapper1
                    className="mb-30"
                    fluid={image1.childImageSharp.fluid}
                    alt="portfolio image"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <ImageWrapper2
                    className="mb-30"
                    fluid={image2.childImageSharp.fluid}
                    alt="portfolio image"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <ImageWrapper2
                    className="mb-30"
                    fluid={image3.childImageSharp.fluid}
                    alt="portfolio image"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ImageWrapper3
                fluid={image4.childImageSharp.fluid}
                alt="portfolio image"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="portfolio portfolio-single portfolio-single-2 pb-100 pt-30">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="portfolio-prev-next portfolio-prev-next-short mb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    {getCurrentMarkdown()[0].previous && (
                      <div className="portfolio-prev">
                        <div className="portfolio-icon portfolio-prev-icon">
                          <Link
                            to={
                              getCurrentMarkdown()[0].previous.frontmatter.path
                            }
                          >
                            <i className="fal fa-long-arrow-alt-left fa-2x" />
                          </Link>
                        </div>
                        <div className="portfolio-prev-content">
                          <h6>Précédent</h6>
                          <Link
                            className="portfolio-link"
                            to={
                              getCurrentMarkdown()[0].previous.frontmatter.path
                            }
                          >
                            {
                              getCurrentMarkdown()[0].previous.frontmatter
                                .client
                            }
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                    <Link className="portfolio-back" to="/realisations">
                      Retour aux Réalisations
                    </Link>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    {getCurrentMarkdown()[0].next && (
                      <div className="portfolio-next">
                        <div className="portfolio-icon portfolio-next-icon">
                          <Link
                            to={getCurrentMarkdown()[0].next.frontmatter.path}
                          >
                            <i className="fal fa-long-arrow-alt-right fa-2x" />
                          </Link>
                        </div>
                        <div className="portfolio-next-content">
                          <h6>Suivant</h6>
                          <Link
                            className="portfolio-link"
                            to={getCurrentMarkdown()[0].next.frontmatter.path}
                          >
                            {getCurrentMarkdown()[0].next.frontmatter.client}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EtudeDeCasTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "etude-de-cas" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            path
          }
        }
        previous {
          frontmatter {
            client
            path
          }
        }
        next {
          frontmatter {
            client
            path
          }
        }
      }
    }

    markdownRemark(
      frontmatter: { template: { eq: "etude-de-cas" }, path: { eq: $path } }
    ) {
      html
      frontmatter {
        path
        date(formatString: "DD MMMM YYYY", locale: "fr")
        title
        description
        client
        technologies
        prestation
        website
        image1 {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        content
        author
        linkedin
        role
        picture {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      excerpt
    }
    imageBanner: file(
      relativePath: { eq: "background/bannerRealisationsOriginal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
